<template>
  <div class="p-4 txt-grey-900 fs-14 fw-500">
    <div class="font-bold fs-24 fw-700 mb-3">{{ $t('lbl_list_of_emr_template') }}</div>

    <div class="bg-white rounded p-6 h-auto overflow-auto mt-2">
      <div class="flex justify-between mb-3">
        <div class="flex gap-5">
          <div class="flex items-center border rounded-md border-grey-300 p-2 mr-2 w-60">
            <IconSearch class="cursor-pointer" />
            <input
              v-model="valueSearch"
              @keypress.enter="getListEmrTemplate"
              class="border-0 txt-grey-500 ml-2 focus:outline-none"
              :placeholder="$t('lbl_enter_name')"
            />
          </div>
        </div>
        <div class="flex">
          <ButtonHoDo
            @click="showModalAddEmrTemplate(true)"
            :title="$t('lbl_add_medical_record_template')"
            class-name="bg-blue-700 text-white rounded-lg h-9 px-3 py-2"
          />
        </div>
      </div>
      <div>
        <el-table
          :data="emrTemplates"
          style="width: 100%"
          row-class-name="text-black text-sm cursor-pointer"
          header-cell-class-name="text-black text-base font-semibold"
          @row-click="goToDetail"
        >
          <el-table-column prop="name" :label="$t('lbl_template_name')"></el-table-column>
          <el-table-column
            prop="created_at"
            :label="$t('lbl_created_date')"
            :formatter="getDateCreate"
          ></el-table-column>
          <el-table-column prop="author.name" :label="$t('lbl_created_user')"></el-table-column>
          <el-table-column
            prop="type"
            :label="$t('lbl_type')"
            :formatter="(row)=> $t(row.is_public === 0 ? 'lbl_for_me' : 'lbl_for_workspace')"
          ></el-table-column>
        </el-table>
        <AppPagination :paginationProps="pagination" @onChangePagination="getListEmrTemplate" />
      </div>
    </div>
    <ModalAddEmrTemplate
      @onCreateSuccess="()=>{
        showModalAddEmrTemplate(false)
        getListEmrTemplate()
      }"
    />
  </div>
</template>

<script>
import { ButtonHoDo } from '@/components/Button'
import { IconSearch } from '@/components/Icon'
import { AppPagination } from '@/components/Common'
import ModalAddEmrTemplate from './ModalAddEmrTemplate.vue'

export default {
  components: {
    ButtonHoDo,
    IconSearch,
    AppPagination,
    ModalAddEmrTemplate
  },
  name: 'ListEmrTemplate',

  data () {
    return {
      valueSearch: '',
      emrTemplates: [],
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      }
    }
  },
  mounted () {
    this.getListEmrTemplate()
  },
  watch: {},
  computed: {
    isAdmin () {
      const isTrue =
        this.$globalClinic &&
        this.$user &&
        this.$user.doctor &&
        this.$globalClinic.doctor_id === this.$user.doctor.id

      return isTrue
    }
  },
  methods: {
    goToDetail (item) {
      if (!item.id) {
        self.$toast.open({
          message: this.$t('lbl_emr_template_not_found'),
          type: 'warning'
        })
      }
      this.$router
        .push({ name: 'EmrTemplateDetail', params: { id: item.id } })
        .catch(e => {})
    },
    async getListEmrTemplate (data) {
      if (!this.$globalClinic && !this.$globalClinic.id) return
      let self = this
      const params = {
        workspace_id: this.$globalClinic.id,
        page: data?.currentPage || this.pagination.currentPage,
        limit: data?.pageSize || this.pagination.pageSize,
        sort: 'created_at',
        sort_by: 'desc'
      }
      if (this.valueSearch) {
        params.keyword = this.valueSearch
      }

      const r = await this.$rf
        .getRequest('DoctorRequest')
        .getListEmrTemplate(params)
        .then(r => {
          self.emrTemplates = r.data?.data?.data || []
          const metaData = r.data?.data?.meta || {}
          self.pagination.totalItems = metaData.total || 0
          self.pagination.currentPage = metaData.page || 1
          self.pagination.pageSize =
            Number(metaData.limit) || this.pagination.pageSize

          return r
        })
        .catch(e => {
          console.log(e)

          return false
        })
      return r
    },
    getDateCreate (row) {
      return (
        row.created_at && window.moment(row.created_at).format('DD/MM/YYYY')
      )
    },
    showModalAddEmrTemplate (show) {
      window.$('#modalAddEmrTemplate').modal(show ? 'show' : 'hide')
    }
  }
}
</script>

<style scoped>
::placeholder {
  color: #98a2b3;
}

.border-bottom-2 {
  border-bottom: 2px solid #20419b !important;
}

.custom:hover {
  background-color: #e4e7ec;
}

.txt-red {
  color: #ff0e0e;
}

.bg-demo {
  background-color: #f6e6ad;
}

.border-custom {
  border: 1px solid #e4e7ec;
}
</style>
